import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import routes, { redirects } from './routes';
import { LandingPages, ReqStatus, User } from './types';
import { getUserLoginState } from './services/userService';
import Error404 from './pages/Error404';
import { isServer } from './util';
import { trackPageViewEvent } from './services/analyticsService';
import {
  isIntercomDisabledForPath,
  registerIntercomMessengerEventTracker,
  updateIntercomSettings,
} from './services/intercomService';
import { useOnPageChange } from './hooks/utilHooks';
import appConstants from './appConstants';
import CookieConsentPopup from './components/CookieConsentPopup';

type UserData = {
  user?: User;
  reqStatus?: ReqStatus;
  userMeta?: {
    landingPageVisited?: LandingPages;
  };
};
type UserDataContextObj = {
  userData: UserData;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
};

export const UserDataContext = React.createContext<UserDataContextObj>({
  userData: {},
  setUserData: () => {},
});

export function App() {
  const [userData, setUserData] = useState<UserData>({});

  // Fetch current user after first render
  useEffect(() => {
    async function loadUserData() {
      setUserData((prevState) => ({
        ...prevState,
        reqStatus: 'pending',
      }));

      const res = await getUserLoginState();
      if (res.err) {
        setUserData((prevState) => ({
          ...prevState,
          user: undefined,
          reqStatus: 'err',
        }));

        return;
      }

      setUserData((prevState) => ({
        ...prevState,
        user: res.data.logged_in ? res.data.user : undefined,
        reqStatus: 'success',
      }));
    }

    // Only check login state with API if browser has sessionid cookie stored (saves an API call if not)
    if (appConstants.HAS_DJANGO_SESSION_COOKIE) {
      loadUserData();
    }
  }, []);

  // Preload common pages user might go to after initial so routing is near instantaneous. This fetches the chunks after first render
  // Use sparingly, since too many can cause performance hits
  useEffect(() => {
    if (!isServer()) {
      routes.HOME.component.preload();
    }
  }, []);

  // Send page view events to analytics service
  useTrackPageChanges();

  useScrollToTopOnPageChange();

  useEffect(() => {
    registerIntercomMessengerEventTracker();
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <UserDataContext.Provider value={{ userData, setUserData }}>
        {/* Default title and meta. Will be overridden by any nested/subsequent component if specified */}
        <Helmet>
          <title>The Original Scavenger Hunt App | Goosechase</title>
          <meta
            name="description"
            content="Goosechase is an online platform that helps you run real-world scavenger hunts and create incredible experiences for your communities. Try it today for free!"
          />
        </Helmet>

        <Switch>
          {/* Redirect from trailing slash routes first */}
          {Object.values(routes).map((route) => {
            if (route.path === '/') {
              return null;
            }

            const pathWithTrailingSlash = route.path.endsWith('/') ? route.path : route.path + '/';

            return (
              <Route
                key={pathWithTrailingSlash}
                exact
                strict
                path={pathWithTrailingSlash}
                render={(props) => {
                  if (props.staticContext) {
                    props.staticContext.statusCode = 301;
                  }
                  return <Redirect to={`${props.location.pathname.slice(0, -1)}${props.location.search}`} />;
                }}
              />
            );
          })}

          {Object.values(routes).map((route) => (
            <Route key={route.path} exact path={route.path}>
              <route.component />
            </Route>
          ))}

          {/* redirects from old pages */}
          {redirects.map((redirect) => (
            <Route
              key={redirect.from}
              exact
              path={redirect.from}
              render={(props) => {
                if (props.staticContext) {
                  props.staticContext.statusCode = redirect.temporary ? 302 : 301;
                }
                return <Redirect to={`${redirect.to}${props.location.search}`} />;
              }}
            />
          ))}

          {/* Catch all route if none of the above match */}
          <Route
            render={({ staticContext }) => {
              // Set status code on router context so server can send that as response
              if (staticContext) {
                staticContext.statusCode = 404;
              }
              return <Error404 />;
            }}
          />
        </Switch>

        <CookieConsentPopup />
      </UserDataContext.Provider>
    </Sentry.ErrorBoundary>
  );
}

function useScrollToTopOnPageChange() {
  const location = useLocation();
  useOnPageChange(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
  });
}

function useTrackPageChanges() {
  const location = useLocation();

  useOnPageChange(() => {
    updateIntercomSettings({
      hide_default_launcher: isIntercomDisabledForPath(location.pathname),
    });

    trackPageViewEvent({
      hash: location.hash,
      pathname: location.pathname,
      search: location.search,
    });
  });
}

const ErrorFallback = () => (
  <div
    css={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <div>Whoops! An error occurred.</div>
    <div css={{ marginTop: '1.6rem' }}>
      Please refresh the page. If the problem persists, please report it to our team at{' '}
      <a href="mailto:hi@goosechase.com">hi@goosechase.com</a>
    </div>
  </div>
);

export default hot(App);
